import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "../screens/layout/AppLayout";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { Spin } from "antd";
import Logout from "../screens/auth/Logout";
import UserCreate from "../screens/user/Create";
import Group from "../screens/group";
import GroupCreate from "../screens/group/Create";
import GroupEdit from "../screens/group/Edit";
import GroupDetail from "../screens/group/Detail";
import Company from "../screens/company";
import CompanyCreate from "../screens/company/Create";
import CompanyEdit from "../screens/company/Edit";
import CompanyDetail from "../screens/company/Detail";
import Asset from "../screens/asset";
import AssetCreate from "../screens/asset/Create";
import AssetDetail from "../screens/asset/Detail";
import AssetEdit from "../screens/asset/Edit";
import Device from "../screens/device";
import DeviceCreate from "../screens/device/Create";
import DeviceDetail from "../screens/device/Detail";
import DeviceEdit from "../screens/device/Edit";
import DeviceType from "../screens/deviceType";
import DeviceTypeCreate from "../screens/deviceType/Create";
import DeviceTypeDetail from "../screens/deviceType/Detail";
import DeviceTypeEdit from "../screens/deviceType/Edit";
import OperationMode from "../screens/operationMode";
import OperationModeCreate from "../screens/operationMode/Create";
import OperationModeEdit from "../screens/operationMode/Edit";
import OperationModeDetail from "../screens/operationMode/operationModeDetail";
import OperationModeDetailCreate from "../screens/operationMode/operationModeDetail/Create";
import OperationModeDetailEdit from "../screens/operationMode/operationModeDetail/Edit";
import MasterReport from "../screens/masterReport";
import MasterReportEmailReceiverEdit from "../screens/masterReport/emailReceiver/Edit";
import MasterReportEmailTemplateEdit from "../screens/masterReport/emailTemplate/Edit";
import MasterReportEmailTargetAssetEdit from "../screens/masterReport/emailTargetAsset/Edit";
import SummaryReport from "../screens/report/summary";
import RPMFuelReport from "../screens/report/rpmFuelReport";
import MEFuelConsumptionReport from "../screens/report/portFuelUsed";
import PortFuelUsedReport from "../screens/report/meFuelConsumption";
import StarboardFuelReport from "../screens/report/starboardFuel";
import DailyReport from "../screens/report/dailyReport";

const NotFound = React.lazy(() => import("../NotFound"));

const Login = React.lazy(() => import("../screens/auth/NewLogin"));
const ForgotPassword = React.lazy(
  () => import("../screens/auth/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("../screens/auth/ResetPassword"));
const Home = React.lazy(() => import("../screens/home"));
const Dashboard = React.lazy(() => import("../screens/dashboard"));
const Profile = React.lazy(() => import("../screens/profile"));
const ChangeMyPassword = React.lazy(
  () => import("../screens/profile/ChangeMyPassword")
);
const ChangePasswordUser = React.lazy(
  () => import("../screens/user/ChangePassword")
);
const User = React.lazy(() => import("../screens/user"));
const UserEdit = React.lazy(() => import("../screens/user/Edit"));
const UserDetail = React.lazy(() => import("../screens/user/Detail"));

const Role = React.lazy(() => import("../screens/role"));
const RoleEdit = React.lazy(() => import("../screens/role/Edit"));
const RoleDetail = React.lazy(() => import("../screens/role/Detail"));

const UserAddressComponent = React.lazy(
  () => import("../screens/user/userAddress")
);
const UserAddressDetail = React.lazy(
  () => import("../screens/user/userAddress/Detail")
);
const UserAddressEdit = React.lazy(
  () => import("../screens/user/userAddress/Edit")
);

const RootNavigator: React.FC = () => {
  const _renderLoading = () => {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spin spinning={true} />
      </div>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Suspense
                fallback={<React.Fragment>{_renderLoading()}</React.Fragment>}
              >
                <Login />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Suspense
                fallback={<React.Fragment>{_renderLoading()}</React.Fragment>}
              >
                <Login />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <Suspense
                fallback={<React.Fragment>{_renderLoading()}</React.Fragment>}
              >
                <ForgotPassword />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password/:resetType/:sessionId"
          element={
            <PublicRoute>
              <Suspense
                fallback={<React.Fragment>{_renderLoading()}</React.Fragment>}
              >
                <ResetPassword />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/daily-report"
          element={
            <PublicRoute>
              <Suspense
                fallback={<React.Fragment>{_renderLoading()}</React.Fragment>}
              >
                <DailyReport />
              </Suspense>
            </PublicRoute>
          }
        />

        <Route path="*" element={<NotFound />} />

        <Route element={<AppLayout />}>
          <Route
            path="/home"
            element={
              <PrivateRoute loginPath="/login">
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute loginPath="/login">
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute loginPath="/login">
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangeMyPassword />
              </PrivateRoute>
            }
          />

          {/* Report */}
          <Route
            path="/report-summary"
            element={
              <PrivateRoute loginPath="/login">
                <SummaryReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/rpm-vs-fuel-cons-vs-speed-vs-ae"
            element={
              <PrivateRoute loginPath="/login">
                <RPMFuelReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/me-fuel-consumption"
            element={
              <PrivateRoute loginPath="/login">
                <MEFuelConsumptionReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/port-fuel-used-vs-running-hour"
            element={
              <PrivateRoute loginPath="/login">
                <PortFuelUsedReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/stb-fuel-used-vs-running-hour"
            element={
              <PrivateRoute loginPath="/login">
                <StarboardFuelReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/stb-fuel-used-vs-running-hour"
            element={
              <PrivateRoute loginPath="/login">
                <StarboardFuelReport />
              </PrivateRoute>
            }
          />

          {/* Device Route */}
          <Route
            path="/device"
            element={
              <PrivateRoute loginPath="/login">
                <Device />
              </PrivateRoute>
            }
          />
          <Route
            path="/device/add"
            element={
              <PrivateRoute loginPath="/login">
                <DeviceCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/device/:deviceId"
            element={
              <PrivateRoute loginPath="/login">
                <DeviceDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/device/:deviceId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <DeviceEdit />
              </PrivateRoute>
            }
          />

          {/* Device Type Route */}
          <Route
            path="/device-type"
            element={
              <PrivateRoute loginPath="/login">
                <DeviceType />
              </PrivateRoute>
            }
          />
          <Route
            path="/device-type/add"
            element={
              <PrivateRoute loginPath="/login">
                <DeviceTypeCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/device-type/:deviceTypeId"
            element={
              <PrivateRoute loginPath="/login">
                <DeviceTypeDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/device-type/:deviceTypeId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <DeviceTypeEdit />
              </PrivateRoute>
            }
          />

          {/* Asset Route */}
          <Route
            path="/asset"
            element={
              <PrivateRoute loginPath="/login">
                <Asset />
              </PrivateRoute>
            }
          />
          <Route
            path="/asset/add"
            element={
              <PrivateRoute loginPath="/login">
                <AssetCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/asset/:assetId"
            element={
              <PrivateRoute loginPath="/login">
                <AssetDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/asset/:assetId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <AssetEdit />
              </PrivateRoute>
            }
          />

          {/* Company Route */}
          <Route
            path="/company"
            element={
              <PrivateRoute loginPath="/login">
                <Company />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/add"
            element={
              <PrivateRoute loginPath="/login">
                <CompanyCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/:companyId"
            element={
              <PrivateRoute loginPath="/login">
                <CompanyDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/company/:companyId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <CompanyEdit />
              </PrivateRoute>
            }
          />

          {/* Group Route */}
          <Route
            path="/group"
            element={
              <PrivateRoute loginPath="/login">
                <Group />
              </PrivateRoute>
            }
          />
          <Route
            path="/group/add"
            element={
              <PrivateRoute loginPath="/login">
                <GroupCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/group/:groupId"
            element={
              <PrivateRoute loginPath="/login">
                <GroupDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/group/:groupId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <GroupEdit />
              </PrivateRoute>
            }
          />

          {/* Master Report Route */}
          <Route
            path="/master-report"
            element={
              <PrivateRoute loginPath="/login">
                <MasterReport />
              </PrivateRoute>
            }
          />
          <Route
            path="/master-report/email-receiver/add"
            element={
              <PrivateRoute loginPath="/login">
                <MasterReportEmailReceiverEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/master-report/email-receiver/:reportEmailReceiverId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <MasterReportEmailReceiverEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/master-report/email-template/add"
            element={
              <PrivateRoute loginPath="/login">
                <MasterReportEmailTemplateEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/master-report/email-template/:templateId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <MasterReportEmailTemplateEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/master-report/email-target-by-asset/add"
            element={
              <PrivateRoute loginPath="/login">
                <MasterReportEmailTargetAssetEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/master-report/email-target-by-asset/:reportEmailTargetAssetId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <MasterReportEmailTargetAssetEdit />
              </PrivateRoute>
            }
          />

          {/* Operation Mode Route */}
          <Route
            path="/operation-mode"
            element={
              <PrivateRoute loginPath="/login">
                <OperationMode />
              </PrivateRoute>
            }
          />
          <Route
            path="/operation-mode/add"
            element={
              <PrivateRoute loginPath="/login">
                <OperationModeCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/operation-mode/:operationModeId"
            element={
              <PrivateRoute loginPath="/login">
                <OperationModeDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/operation-mode/:operationModeId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <OperationModeEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/operation-mode/:operationModeId/detail/add"
            element={
              <PrivateRoute loginPath="/login">
                <OperationModeDetailCreate />
              </PrivateRoute>
            }
          />
          <Route
            path="/operation-mode/:operationModeId/detail/:operationModeDetailId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <OperationModeDetailEdit />
              </PrivateRoute>
            }
          />

          {/* Admin Route */}
          <Route
            path="/admin"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserCreate userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />

          {/* Role Route */}
          <Route
            path="/role"
            element={
              <PrivateRoute loginPath="/login">
                <Role />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/add"
            element={
              <PrivateRoute loginPath="/login">
                <RoleEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/:roleId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <RoleEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/:roleId"
            element={
              <PrivateRoute loginPath="/login">
                <RoleDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/users/:userId/address/:addressId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserAddressEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:userId/address/:addressId"
            element={
              <PrivateRoute loginPath="/login">
                <UserAddressDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/logout"
            element={
              <PublicRoute>
                <Logout />
              </PublicRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default RootNavigator;
