import { Typography } from "antd";
import React from "react";
import styled from "styled-components";
import Divider from "./Divider";

const { Paragraph } = Typography;

type FooterType = {
  generateAt: string;
  pageNumber: string;
};

const Footer: React.FC<FooterType> = ({ generateAt, pageNumber }) => {
  return (
    <FooterWrapper>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{generateAt}</span>
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 5px;
  width: 100%;
  text-align: center;
  padding-right: 20px;
`;

const FooterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  color: black;
`;

export default Footer;
