import { useNavigate } from "react-router-dom";
import usePermission from "../../hooks/usePermission";
import React, { useEffect, useMemo, useState } from "react";
import {
  HeaderSection,
  AppTable,
  useFetchList,
  PAGE_SIZE_OPTIONS,
  IAppTableColumn,
  formatDate,
  BaseResponsePaginationProps,
} from "@qlibs/react-components";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";
import { Button, Image, Modal, Tag, message } from "antd";
import { DeviceProperties } from "./types/device.type";
import { httpRequest } from "../../helpers/api";
import { EDateFormat } from "../../const/config";
import { AssetProperties } from "../asset/types/asset.type";
import LinkTable from "../../components/LinkTable";
import { CompanyProperties } from "../company/types/company.type";
import useCompanies from "../../zustand/useGroup";
import useMasterData from "../../hooks/useMasterData";

const FEATURE = "DEVICE";

const Device = () => {
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();
  const { company } = useCompanies();
  const { getListCompanies, getListAssets, setAssets, companies, assets } =
    useMasterData();

  const handleCreateDevice = () => {
    navigate({ pathname: "/device/add" });
  };

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalRestoreOpen, setIsModalRestoreOpen] = useState(false);
  const [currentData, setCurrentData] = useState<DeviceProperties>();
  const [forceDeleteId, setForceDeleteId] = useState<string>();
  const [companyId, setCompanyId] = useState<string>(company?.companyId);

  const {
    isLoading,
    data,
    pagination,
    query,
    setData,
    setQuery,
    changePage,
    changeLimit,
    handleSearch,
    pageQueries,
    filterDropdown,
  } = useFetchList<DeviceProperties>({
    httpRequest: httpRequest as any,
    endpoint: "device",
    limit: +PAGE_SIZE_OPTIONS[1],
    initialQuery: {
      filterCompanyIds: company?.companyId || "",
    },
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
  });

  async function deleteData() {
    try {
      if (currentData) {
        const forceDelete =
          forceDeleteId && forceDeleteId === currentData.deviceId;
        await httpRequest.delete("/device/" + currentData.deviceId, {
          params: {
            forceDelete: forceDelete || undefined,
          },
        });
        message.success(
          `Success ${forceDelete ? "delete" : "archive"} ${currentData.name}`
        );

        setQuery({
          limit: +PAGE_SIZE_OPTIONS[1],
          filterCompanyIds: company?.companyId || "",
        });
      } else {
        message.error("Current data not found");
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalDeleteOpen(false);
      setCurrentData(undefined);
    }
  }

  async function restoreData() {
    try {
      if (currentData) {
        await httpRequest.patch("/device/" + currentData.deviceId + "/restore");
        message.success(`Success restore ${currentData.name}`);

        setQuery({
          limit: +PAGE_SIZE_OPTIONS[1],
        });
      } else {
        message.error("Current data not found");
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalRestoreOpen(false);
      setCurrentData(undefined);
      setForceDeleteId(undefined);
    }
  }

  async function handleRestoreDevice(id: any) {
    setIsModalRestoreOpen(true);
    const found = data.find((item) => item.deviceId === id);
    if (found) {
      setCurrentData(found);
    }
  }

  async function handleDeleteDevice(id: any, forced?: boolean) {
    setIsModalDeleteOpen(true);
    const found = data.find((item) => item.deviceId === id);
    if (found) {
      setCurrentData(found);
    }

    if (forced) {
      setForceDeleteId(id);
    }
  }

  function handleCancelDelete() {
    setIsModalDeleteOpen(false);
    setIsModalRestoreOpen(false);
    setCurrentData(undefined);
  }

  function isArchived(id?: string) {
    if (id) {
      const found = data.find((item) => item.deviceId === id && item.deletedAt);
      if (found) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    if (companyId) {
      getListAssets({ filterCompanyIds: companyId });
    } else {
      setAssets([]);
    }
  }, [companyId]);

  useEffect(() => {
    getListCompanies();
  }, []);

  const filterAssetOptions = useMemo(() => {
    const data = assets.map((item) => ({
      value: item.assetId,
      label: item.name,
    }));
    return [{ value: "all", label: "All" }, ...data];
  }, [assets]);

  const columns: IAppTableColumn<DeviceProperties>[] = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      type: "detail",
      keyId: "deviceId",
      render: (value, records) => (
        <LinkTable onPress={() => navigate("/device/" + records.deviceId)}>
          {records.deletedAt ? (
            <Tag color="#ff5500" className="mb-2">
              Archived
            </Tag>
          ) : (
            false
          )}
          {value}
        </LinkTable>
      ),
    },
    {
      title: "ASSET",
      dataIndex: "assetId",
      key: "assetId",
      render: (_, records) => (records.asset ? `${records.asset.name}` : "N/A"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (value, _) => (
        <Tag color={value === "active" ? "success" : "error"}>
          {value.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, _) => formatDate(value, EDateFormat.DEFAULT),
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value, _) => formatDate(value, EDateFormat.DEFAULT),
    },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      actions: [
        isUserHasPermission([FEATURE + ".DETAIL"])
          ? {
              key: "detail",
              label: "Detail",
              onPress: (id) => navigate("/device/" + id),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".UPDATE"])
          ? {
              key: "update",
              label: "Update",
              onPress: (id) => navigate("/device/" + id + "/edit"),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".DELETE"])
          ? {
              key: "archive",
              label: "Delete",
              color: "#ff4d4f",
              disabled: (id) => isArchived(id),
              disabledTooltip: "This data is already archived",
              onPress: (id) => handleDeleteDevice(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".RESTORE"])
          ? {
              key: "restore",
              label: "Restore",
              color: "#1890ff",
              disabled: (id) => !isArchived(id),
              disabledTooltip: "This data not in archived",
              onPress: (id) => handleRestoreDevice(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + ".FORCE_DELETE"])
          ? {
              key: "delete",
              label: "Force Delete",
              onPress: (id) => handleDeleteDevice(id, true),
            }
          : undefined,
      ],
    },
  ];

  const filterCompanyOptions = useMemo(() => {
    const data = companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
    return [{ value: "", label: "All" }, ...data];
  }, [companies]);

  const filterData: any = [
    {
      type: "search",
      key: "search",
      label: "Search",
      placeholder: "Search by device name",
      onChange: (value: any) => {
        handleSearch(value);
      },
      colSpan: isUserHasPermission([FEATURE + ".DELETE"]) ? 6 : 10,
    },
    {
      type: "select",
      key: "company",
      label: "Company",
      options: filterCompanyOptions,
      value: pageQueries.filterCompanyIds || "All",
      onChange: (value: any) => {
        setCompanyId(value);
        filterDropdown({
          filterCompanyIds: value === "all" ? "" : value,
        });
      },
      colSpan: 4,
    },
    {
      type: "select",
      key: "asset",
      label: "Filter Asset",
      options: filterAssetOptions,
      onChange: (value: any) => {
        filterDropdown({
          filterAssetIds: value === "all" ? "" : value,
        });
      },
      colSpan: 4,
    },
    {
      type: "select",
      key: "status",
      label: "Status",
      options: [
        {
          value: "all",
          label: "All",
        },
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterStatus: value === "all" ? "" : value,
        });
      },
      colSpan: 4,
    },
    {
      type: "select",
      key: "filterData",
      label: "Filter Data",
      options: [
        {
          value: "default",
          label: "Default",
        },
        {
          value: "show",
          label: "Include Archive Data",
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterArchived: value === "default" ? "" : value,
        });
      },
      colSpan: 4,
    },
  ].filter((item) =>
    !isUserHasPermission([FEATURE + ".DELETE"])
      ? item.key !== "filterData"
      : item
  );

  return (
    <React.Fragment>
      <HeaderSection
        title={"Device"}
        rightAction={[
          isUserHasPermission([FEATURE + ".CREATE"]) ? (
            <Button type="primary" onClick={handleCreateDevice}>
              Add Device
            </Button>
          ) : (
            false
          ),
        ]}
      />

      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status: pageQueries.status || "all",
          filterData: pageQueries.filterData || "default",
          filterAsset: pageQueries.filterAsset || "all",
          company: company.name || "all",
        }}
        filters={[filterData]}
      />

      <AppTable
        isLoading={isLoading}
        keyId="deviceId"
        columns={columns}
        data={data}
        pagination={pagination}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        // _table={{
        //   scroll:{ x: '100%' }
        // } as any}
      />

      <Modal
        title={
          forceDeleteId ? "Force Delete Confirmation" : "Delete Confirmation"
        }
        open={isModalDeleteOpen}
        onOk={deleteData}
        okButtonProps={{
          style: {
            background: "red",
          },
        }}
        onCancel={handleCancelDelete}
      >
        {forceDeleteId ? (
          <p>
            Are you sure want to permanently delete this data? This data will be
            deleted and only can't be undo.
          </p>
        ) : (
          <p>
            Are you sure want to delete this data? This data will be archived
            and only User with restore permission that can undo this action.
          </p>
        )}
      </Modal>

      <Modal
        title="Restore Confirmation"
        open={isModalRestoreOpen}
        onOk={restoreData}
        okButtonProps={{
          style: {
            background: "blue",
          },
        }}
        onCancel={handleCancelDelete}
      >
        <p>Are you sure want to restore this data?.</p>
      </Modal>
    </React.Fragment>
  );
};
export default Device;
