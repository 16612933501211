import React from "react";
import { Image } from "antd";

type ImageWrapperType = {
  src: any;
  alt: string;
  title: string;
  width: number;
  height: number;
  style: React.CSSProperties;
  isPriority?: boolean;
};

const ImageWrapper: React.FC<ImageWrapperType> = ({
  src,
  alt,
  title,
  width,
  height,
  style,
  isPriority = true,
}) => {
  return (
    <img
      src={src || "/images/no-preview.jpg"}
      width={width}
      height={height}
      alt={alt}
      title={title}
      style={{ ...style }}
      // fallback="/images/no-preview.jpg"
    />
  );
};

export default ImageWrapper;
