import { Image, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Header from "../../../components/dailyReport/Header";
import {
  columns,
  columnsAEFuel,
  columnsMEFuel,
  columnsPortFuel,
  columnsRPMSpeed,
  columnsStarboardFuel,
  columnsVesselTracking,
} from "../../../helpers/charts";
import Footer from "../../../components/dailyReport/Footer";
import ImageWrapper from "../../../components/dailyReport/ImageWrapper";
import Chart from "../../../components/Chart";
import useAuthApp from "../../../hooks/useAuthApp";
import { BaseResponseProps, getErrorMessage } from "@qlibs/react-components";
import { httpRequest } from "../../../helpers/api";

const { Paragraph } = Typography;

type DailyReportType = {
  currentPath?: any;
};

const DailyReport: React.FC<DailyReportType> = ({ currentPath }) => {
  const { doLogin } = useAuthApp();

  const [mapImages, setMapImages] = useState({
    mapLeft: undefined,
    mapRight: undefined,
  });

  const [data, setData] = useState<any[]>([
    {
      key: "1",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
    {
      key: "2",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
    {
      key: "3",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
    {
      key: "4",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
    {
      key: "5",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
    {
      key: "6",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
    {
      key: "7",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
    {
      key: "8",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
    {
      key: "9",
      description: "Port ME Engine Speed",
      value: 0.0,
      unit: "RPM",
      description2: "Port ME Total Fuel Used",
      value2: 48.59,
      unit2: "Liter",
    },
  ]);

  const [dataSourceVesselTracking, setDataSourceVesselTracking] = useState<
    any[]
  >([
    {
      key: "1",
      header: "Start",
      position: "Start ° ′ ″ S ° ′ ″ E Dec Knot",
      time: "09 Dec 2023, 17:00",
      speed: "0.54 Knot",
    },
    {
      key: "2",
      header: "End",
      position: "Start ° ′ ″ S ° ′ ″ E Dec Knot",
      time: "09 Dec 2023, 17:00",
      speed: "0.54 Knot",
    },
    {
      key: "3",
      header: "Average Speed",
      position: "0.27 Knot",
      time: "",
      speed: "",
    },
    {
      key: "4",
      header: "Total Cruise",
      position: "0.27 Knot",
      time: "",
      speed: "",
    },
  ]);

  const [dataSourceRPMSpeed, setDataSourceRPMSpeed] = useState<any[]>([
    {
      key: "1",
      hour: "Port RPM",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "2",
      hour: "STB RPM",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "3",
      hour: "Port Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "4",
      hour: "STB Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "5",
      hour: "AE Running Time",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "6",
      hour: "Speed",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
  ]);

  const [dataSourcePortFuel, setDataSourcePortFuel] = useState<any[]>([
    {
      key: "1",
      hour: "Port Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "2",
      hour: "Port Running Hour",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
  ]);

  const [dataSourceStarboardFuel, setDataSourceStarboardFuel] = useState<any[]>(
    [
      {
        key: "1",
        hour: "Stb Fuel Cons",
        ...Object.fromEntries(
          Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
        ),
      },
      {
        key: "2",
        hour: "Stb RunningHour",
        ...Object.fromEntries(
          Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
        ),
      },
    ]
  );

  const [dataSourceMEFuel, setDataSourceMEFuel] = useState<any[]>([
    {
      key: "1",
      hour: "Port Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "2",
      hour: "Stb Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "3",
      hour: "Avg Fuel Cons ",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "4",
      hour: "Max Fuel Cons ",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
  ]);

  const [dataSourceAEFuel, setDataSourceAEFuel] = useState<any[]>([
    {
      key: "1",
      hour: "AE 1 Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "2",
      hour: "AE 2 Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "3",
      hour: "AE 3 Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
    {
      key: "4",
      hour: "Avg Fuel Cons",
      ...Object.fromEntries(
        Array.from({ length: 24 }, (_, i) => [(i + 17) % 24, 0])
      ),
    },
  ]);

  const updateData = () => {
    const renderData = (data: any[]) => {
      return data.map((item) => ({
        ...item,
        ...Object.fromEntries(
          Array.from({ length: 24 }, (_, i) => [
            (i + 17) % 24,
            Math.floor(Math.random() * 100),
          ])
        ),
      }));
    };

    const newDataRPMSpeed = renderData(dataSourceRPMSpeed);
    const newDataPortFuel = renderData(dataSourcePortFuel);
    const newDataStarboardFuel = renderData(dataSourceStarboardFuel);
    const newDataMEFuel = renderData(dataSourceMEFuel);
    const newDataAEFuel = renderData(dataSourceAEFuel);

    setDataSourceRPMSpeed(newDataRPMSpeed);
    setDataSourcePortFuel(newDataPortFuel);
    setDataSourceStarboardFuel(newDataStarboardFuel);
    setDataSourceMEFuel(newDataMEFuel);
    setDataSourceAEFuel(newDataAEFuel);
  };

  async function generateImage(withoutPath?: boolean) {
    try {
      const zoom = withoutPath ? 8 : 11;
      const lat = "-5.4595718249719685";
      const lng = "105.26643140086992";
      const path = `color:0xff0000ff|weight:5`;
      const constructUrl = withoutPath
        ? `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=535x300&markers=color:red%7C${lat},${lng}&maptype=satellite&key=${process.env.REACT_APP_GMAPS_API_KEY}`
        : `https://maps.googleapis.com/maps/api/staticmap?size=535x300&markers=color:red%7C${lat},${lng}${
            currentPath && currentPath.length > 0
              ? `&path=color:yellow|weight:3|${path}`
              : `&zoom=${zoom}`
          }&maptype=satellite&key=${
            process.env.REACT_APP_GMAPS_API_KEY
          }&sensor=false`;
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api-signature/generate-signature`,
        data: {
          url: constructUrl,
          privateKey: process.env.REACT_APP_GMAPS_PRIVATE_KEY,
        },
      });

      console.log(res.data.payload.result);
      return res.data.payload.result;
    } catch (error: any) {
      console.error("failed generate static map", error);
    }
  }

  const generateToken = async () => {
    try {
      const resultAuthLogin = await axios.post<
        BaseResponseProps<{
          access_token: string;
          refresh_token: string;
        }>
      >(process.env.REACT_APP_BASE_URL + "/auth/generate-token");

      console.log(resultAuthLogin.data.payload.access_token);
      localStorage.setItem(
        "token-daily-report",
        resultAuthLogin.data.payload.access_token
      );
    } catch (error) {
      console.log(getErrorMessage(error));
    }
  };

  useEffect(() => {
    const renderMapImage = async () => {
      const mapLeft = await generateImage();
      const mapRight = await generateImage(true);

      setMapImages({
        mapLeft,
        mapRight,
      });
    };

    renderMapImage();
  }, []);

  useEffect(() => {
    generateToken();
    updateData();
  }, []);

  return (
    <Wrapper>
      {/* Summary */}
      <DataReport pageBreak>
        <Header />
        <TitleReport>
          <Paragraph
            style={{ fontWeight: "600", fontSize: 18, textAlign: "center" }}
          >
            Summary
          </Paragraph>
        </TitleReport>
        <div>
          <StyledTable dataSource={data} columns={columns} pagination={false} />
        </div>

        <Footer
          generateAt="Report generated at: Tue Dec 12 2023 03:07:22"
          pageNumber="1"
        />
      </DataReport>

      {/* Vessel Tracking */}
      <DataReport pageBreak>
        <Header />
        <TitleReport>
          <Paragraph
            style={{ fontWeight: "600", fontSize: 18, textAlign: "center" }}
          >
            Vessel Tracking
          </Paragraph>
        </TitleReport>
        <div>
          <StyledTableVar2
            dataSource={dataSourceVesselTracking}
            columns={columnsVesselTracking}
            pagination={false}
          />
        </div>
        <DailyReportMapWrapper>
          <ImageWrapper
            alt="map-left"
            title="map left"
            style={{ objectFit: "contain", width: "49.5%" }}
            src={mapImages.mapLeft}
            height={300}
            width={300}
          />
          <ImageWrapper
            alt="map-right"
            title="map right"
            style={{ objectFit: "contain", width: "49.5%" }}
            src={mapImages.mapRight}
            height={300}
            width={300}
          />
        </DailyReportMapWrapper>
        <Footer
          generateAt="Report generated at: Tue Dec 12 2023 03:07:22"
          pageNumber="2"
        />
      </DataReport>

      {/* RPM vs Speed vs Fuel Consumption vs AE Running Time */}
      <DataReport pageBreak>
        <Header />
        <DataChart>
          <Chart
            isReportDaily
            title="RPM vs Speed vs Fuel Consumption vs AE Running Time"
          />
        </DataChart>
        <div style={{ marginTop: 20 }}>
          <StyledTableVar2
            dataSource={dataSourceRPMSpeed}
            columns={columnsRPMSpeed}
            pagination={false}
          />
        </div>
        <Footer
          generateAt="Report generated at: Tue Dec 12 2023 03:07:22"
          pageNumber="3"
        />
      </DataReport>

      {/* Port Fuel Consumption vs Running Hour */}
      <DataReport pageBreak>
        <Header />
        <DataChart>
          <Chart isReportDaily title="Port Fuel Consumption vs Running Hour" />
        </DataChart>
        <div style={{ marginTop: 20 }}>
          <StyledTableVar2
            dataSource={dataSourcePortFuel}
            columns={columnsPortFuel}
            pagination={false}
          />
        </div>
        <Footer
          generateAt="Report generated at: Tue Dec 12 2023 03:07:22"
          pageNumber="4"
        />
      </DataReport>

      {/* Starboard Fuel Consumption vs Running Hour */}
      <DataReport pageBreak>
        <Header />
        <DataChart>
          <Chart
            isReportDaily
            title="Starboard Fuel Consumption vs Running Hour"
          />
        </DataChart>
        <div style={{ marginTop: 20 }}>
          <StyledTableVar2
            dataSource={dataSourceStarboardFuel}
            columns={columnsStarboardFuel}
            pagination={false}
          />
        </div>
        <Footer
          generateAt="Report generated at: Tue Dec 12 2023 03:07:22"
          pageNumber="5"
        />
      </DataReport>

      {/* ME Fuel Consumption */}
      <DataReport pageBreak>
        <Header />
        <DataChart>
          <Chart isReportDaily title="ME Fuel Consumption" />
        </DataChart>
        <div style={{ marginTop: 20 }}>
          <StyledTableVar2
            dataSource={dataSourceMEFuel}
            columns={columnsMEFuel}
            pagination={false}
          />
        </div>
        <Footer
          generateAt="Report generated at: Tue Dec 12 2023 03:07:22"
          pageNumber="6"
        />
      </DataReport>

      {/* AE Fuel Consumption */}
      <DataReport>
        <Header />
        <DataChart>
          <Chart isReportDaily title="AE Fuel Consumption" />
        </DataChart>
        <div style={{ marginTop: 20 }}>
          <StyledTableVar2
            dataSource={dataSourceAEFuel}
            columns={columnsAEFuel}
            pagination={false}
          />
        </div>
        <Footer
          generateAt="Report generated at: Tue Dec 12 2023 03:07:22"
          pageNumber="7"
        />
      </DataReport>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 5px;
`;

export const TitleReport = styled.div`
  display: flex;
  justify-content: center;
`;

const DataReport = styled.div<{ pageBreak?: boolean }>`
  margin-top: 0px;
  ${(props) => props.pageBreak && `page-break-after: always;`}
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #264284;
    color: white;

    text-align: center;
    border-radius: 0px !important;
    border: 1px solid #264284;
    padding: 1.5px !important;
  }

  .ant-table-tbody > tr > td {
    border: 1px solid #264284;
    padding: 10px !important;
    text-align: center;
    padding: 1.5px !important;
  }

  .ant-table-cell::before {
    content: none !important;
  }
`;

const StyledTableVar2 = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: grey;
    color: black;
    text-align: center;
    border-radius: 0px !important;
    border: 1px solid #264284;
    padding: 1.5px !important;
  }

  .ant-table-thead > tr > th:first-child {
    background-color: #264284;
    color: white;
  }

  .ant-table-tbody > tr > td {
    border: 1px solid #264284;
    padding: 10px !important;
    text-align: center;
    padding: 1.5px !important;
  }

  .ant-table-tbody > tr > td:first-child {
    background-color: #264284;
    color: white;
    text-align: center;
  }

  .ant-table-cell::before {
    content: none !important;
  }
`;

const DailyReportMapWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
`;

const DataChart = styled.div`
  width: 100%;
`;

export default DailyReport;
