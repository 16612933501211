import { UserProperties } from "../../../services/openapi";
import { AssetProperties } from "../../asset/types/asset.type";
import { CompanyProperties } from "../../company/types/company.type";

//report email template
export type ReportEmailTemplateProperties = {
  templateId?: string;
  templateName: string;
  platform?: string;
  subject?: string;
  externalId?: string;
  htmlContent: any;
  designContent: any;
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
};

export const initialReportEmailTemplate: ReportEmailTemplateProperties = {
  templateId: "",
  templateName: "",
  platform: "",
  subject: "",
  externalId: "",
  htmlContent: "",
  designContent: "",
  status: "",
  createdAt: new Date(),
  updatedAt: new Date(),
};

export interface ICreateReportTemplate {
  templateId: string;
  templateName: string;
  platform: string;
  subject: string;
  externalId: string;
  htmlContent: string;
  designContent: string;
  status: string;
}

// report email receiver
export type ReportEmailReceiverProperties = {
  reportEmailReceiverId: string;
  userId: string;
  companyId: string;
  status: string;
  createdByUserId: string;
  userCreator?: {
    name?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
  };
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  user?: UserProperties;
  company?: CompanyProperties;
};

export const initialReportEmailReceiver: ReportEmailReceiverProperties = {
  reportEmailReceiverId: "",
  userId: "",
  companyId: "",
  status: "",
  createdByUserId: "",
};

export interface ICreateReportEmailReceiver {
  userId: string;
  companyId: string;
  status: string;
}

//report email target asset
export type ReportEmailTargetAssetProperties = {
  reportEmailTargetAssetId: string;
  templateId: string;
  companyId: string;
  assetId: string;
  sendingAt: Date;
  timeFrom: string;
  timeTo: string;
  frequency: string;
  dayOfWeek?: string;
  dateOfMonth?: string;
  dataThresholdMin?: string;
  scheduleAt: string;
  cronJobName: string;
  status: string;
  createdByUserId?: string;
  userCreatorName?: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  asset?: AssetProperties;
  company?: CompanyProperties;
  reportEmailTarget?: any;
};

export const initialReportEmailTargetAsset: ReportEmailTargetAssetProperties = {
  reportEmailTargetAssetId: "",
  templateId: "",
  companyId: "",
  assetId: "",
  sendingAt: new Date(),
  timeFrom: "",
  timeTo: "",
  frequency: "",
  dayOfWeek: "",
  dateOfMonth: "",
  dataThresholdMin: "",
  scheduleAt: "",
  cronJobName: "",
  status: "",
  createdByUserId: "",
  createdAt: new Date(),
  updatedAt: new Date(),
};

export interface ICreateReportEmailTargetAsset {
  reportEmailTargetAssetId: string;
  templateId: string;
  companyId: string;
  assetId: string;
  sendingAt: Date;
  timeFrom: string;
  timeTo: string;
  frequency: string;
  dayOfWeek?: string;
  dateOfMonth?: string;
  dataThresholdMin?: string;
  status: string;
  createdByUserId: string;
  createdAt: Date;
  updatedAt: Date;
}
