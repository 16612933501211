import React from "react";
import FilterReport from "../../../components/FilterReport";
import styled from "styled-components";
import { Card, Select } from "antd";
import { AppTable, IAppTableColumn } from "@qlibs/react-components";

const SummaryReport = () => {
  const dataOptionTotalCruise = [
    {
      label: "KM",
      value: "KM",
    },
    {
      label: "NM",
      value: "NM",
    },
    {
      label: "MILES",
      value: "MILES",
    },
  ];

  const dataOptionAverageSpeed = [
    {
      label: "KNOT",
      value: "KNOT",
    },
    {
      label: "KM/H",
      value: "KM/H",
    },
  ];

  const columns: IAppTableColumn<any>[] = [
    {
      title: "DESRIPTION",
      dataIndex: "description",
      key: "description",
      keyId: "reportId",
      render: (value, records) => {
        return <div>{value || "N/A"}</div>;
      },
    },
    {
      title: "VALUE",
      dataIndex: "value",
      key: "value",
      render: (value, records) => {
        return <div>{value || "N/A"}</div>;
      },
    },
    {
      title: "UNIT",
      dataIndex: "unit",
      key: "unit",
      render: (value, records) => {
        return <div>{value || "N/A"}</div>;
      },
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      render: (value, records) => {
        return <div>{value || "N/A"}</div>;
      },
    },
    {
      title: "VALUE",
      dataIndex: "value",
      key: "value",
      render: (value, records) => {
        return <div>{value || "N/A"}</div>;
      },
    },
    {
      title: "UNIT",
      dataIndex: "unit",
      key: "unit",
      render: (value, records) => {
        return <div>{value || "N/A"}</div>;
      },
    },
  ];

  const _renderDetailInfo = (
    label: string,
    value: any,
    unit?: string,
    dataSelect?: any[]
  ) => {
    return (
      <Card style={{ width: "100%", height: 100, boxShadow: "none" }}>
        <div style={{ marginTop: -10 }}>
          <Label>{label}</Label>
          <div style={{ fontWeight: "bold", fontSize: 16, marginTop: 10 }}>
            {value} {unit && <Unit>{unit}</Unit>}{" "}
            {dataSelect && dataSelect?.length > 0 && (
              <Select
                showSearch
                style={{ width: "40%", marginLeft: 5 }}
                onChange={(e) => {}}
                options={dataSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            )}
          </div>
        </div>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <FilterReport />
      <ContainerDetailInfo>
        {_renderDetailInfo(
          "Total Cruise",
          "0.00",
          undefined,
          dataOptionTotalCruise
        )}
        {_renderDetailInfo(
          "Average Speed",
          "0.00",
          undefined,
          dataOptionAverageSpeed
        )}
        {_renderDetailInfo("Distance", "0.00", "KM", undefined)}
        {_renderDetailInfo(
          "Total AE Running Time",
          "00:00:00",
          "Hour",
          undefined
        )}
      </ContainerDetailInfo>
      <div style={{ marginTop: 15 }}>
        <AppTable
          // isLoading={isLoading}
          keyId="reportId"
          columns={columns}
          data={[]}
          pagination={null as any}
          onChangePage={null as any}
          onChangeLimit={null as any}
        />
      </div>
    </React.Fragment>
  );
};

const ContainerDetailInfo = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const DetailInfo = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  width: 40%;
  height: 80px;
`;

const Label = styled.div`
  color: #768499;
  font-size: 14px;
`;

const Unit = styled.span`
  color: #768499;
  font-size: 14px;
`;

export default SummaryReport;
