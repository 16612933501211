import React from "react";
import { Card, Col, Row } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import { dummyMultiItemCharts } from "../helpers/charts";
import styled from "styled-components";

highchartsMore(Highcharts);
solidGauge(Highcharts);

type ChartType = {
  title: string;
  subtitle?: string;
  isReportDaily?: boolean;
};

const Chart: React.FC<ChartType> = ({
  title,
  subtitle,
  isReportDaily = false,
}) => {
  return (
    <>
      {!isReportDaily && (
        <Row style={{ marginTop: 20 }} gutter={15}>
          <Col span={24}>
            <Card
              style={{
                boxShadow: "none",
              }}
            >
              <div style={{ fontSize: 16, fontWeight: "bold" }}>{title}</div>
              <div style={{ color: "#768499", fontSize: 12 }}>{subtitle}</div>
              <div style={{ marginTop: 20 }}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    ...dummyMultiItemCharts,
                    chart: {
                      zoomType: "xy",
                      height: 480,
                      // width: 800,
                    },
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      )}
      {isReportDaily && (
        <div style={{ width: "100%" }}>
          {title && (
            <WrapperTitle>
              <div style={{ fontSize: 18, fontWeight: "bold" }}>{title}</div>
            </WrapperTitle>
          )}
          <div
            style={{
              marginTop: 20,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                ...dummyMultiItemCharts,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

const WrapperTitle = styled.div`
  display: flex;
  justify-content: center;
`;

export default Chart;
