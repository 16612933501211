import React, { useEffect } from "react";
import RootNavigator from "./navigation/RootNavigator";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./assets/app.css";
import "antd/dist/reset.css";
import ConfigProvider from "./context/ConfigProvider";
import { Alert } from "antd";
import { ThemeProvider } from "styled-components";
import { theme } from "./assets/theme";
import { AuthProvider } from "react-auth-kit";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./i18n";

const { ErrorBoundary } = Alert;
function App() {
  return (
    <ErrorBoundary>
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider
            authType="localstorage"
            authName={"_auth"}
            // cookieDomain={window.location.hostname}
            // cookieSecure={window.location.protocol === "https:"}
          >
            <RootNavigator />
          </AuthProvider>
        </ThemeProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
