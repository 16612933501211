import { message } from "antd";
import { useState } from "react";
import { httpRequest } from "../helpers/api";
import {
  getErrorMessage,
  saveToken,
  BaseResponseProps,
} from "@qlibs/react-components";
import { useSignIn } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../const/config";
import useMasterData from "./useMasterData";

type Props = {
  apiLoginUrl?: string;
  apiGetMyProfileUrl?: string;
};

export default function useAuthApp(props?: Props) {
  const navigate = useNavigate();
  const signIn = useSignIn();

  const { getListGroup } = useMasterData();

  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);
  const [isShowCompany, setIsShowCompany] = useState<boolean>(false);

  const doLogin = async (
    data: { email: string; password: string },
    callback?: () => void
  ) => {
    setIsAuthLoading(true);
    try {
      const resultAuthLogin = await axios.post<
        BaseResponseProps<{
          access_token: string;
          refresh_token: string;
        }>
      >(
        props?.apiLoginUrl || process.env.REACT_APP_BASE_URL + "/auth/signin",
        data
      );

      if (!resultAuthLogin) {
        setIsAuthLoading(false);
        message.error("Login failed. Empty response.");
        return;
      }

      console.log(resultAuthLogin);
      const resProfile: any = await axios.get<
        BaseResponseProps<{
          token: string;
        }>
      >(
        props?.apiGetMyProfileUrl ||
          process.env.REACT_APP_BASE_URL + "/users/me",
        {
          headers: {
            Authorization:
              "Bearer " + resultAuthLogin.data.payload.access_token,
          },
        }
      );
      console.log("resProfile", resProfile);

      if (!resProfile) {
        message.error("Login failed. No profile.");
        setIsAuthLoading(false);
        return;
      }

      if (
        !resProfile?.data?.payload?.role?.permissions?.AUTH?.includes(
          "LOGIN_CMS_ADMIN"
        ) &&
        !resProfile?.data?.payload?.role?.permissions?.AUTH?.includes(
          "LOGIN_CMS"
        )
      ) {
        message.error("Your account does not have access rights to login");
        setIsAuthLoading(false);
        return;
      }

      if (resultAuthLogin) {
        saveToken(resultAuthLogin.data.payload.access_token);
      }

      setIsShowCompany(true);

      if (
        signIn({
          token: resultAuthLogin.data.payload.access_token,
          expiresIn: 10000,
          tokenType: "Bearer",
          authState: resProfile.data.payload,
          // authState: { token: resultAuthLogin.data.payload.token },
          // refreshToken: res.data.refreshToken, // Only if you are using refreshToken feature
          // refreshTokenExpireIn: res.data.refreshTokenExpireIn, // Only if you are using refreshToken feature
        })
      ) {
        // Redirect or do-something
        // console.log(resProfile)
        if (callback) {
          // callback();
        } else {
          // navigate(config.defaultPath || "/home", { replace: true });
        }
        // message.success("Welcome to " + process.env.REACT_APP_WEBSITE_NAME);
      } else {
        message.error("Login failed.");
        //Throw error
      }
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
    }

    setIsAuthLoading(false);
  };

  return {
    isAuthLoading,
    doLogin,
    isShowCompany,
  };
}
