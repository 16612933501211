import React from "react";
import styled from "styled-components";

const Divider = () => {
  return <DividerCustome />;
};

const DividerCustome = styled.div`
  width: 104%;
  height: 3px;
  background-color: #264284;
  margin-top: -30px;
  margin-bottom: 20px;
`;

export default Divider;
