import React from "react";
import styled from "styled-components";
import FilterReport from "../../../components/FilterReport";
import Chart from "../../../components/Chart";

const PortFuelUsedReport = () => {
  return (
    <div>
      <FilterReport />
      <Chart
        title="Port Fuel Used vs Running Hour"
        subtitle="Value of line chart with the dotted line is only estimated data because the data is not yet available"
      />
    </div>
  );
};

export default PortFuelUsedReport;
