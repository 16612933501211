const optionsGauge = {
  chart: {
    type: "gauge",
    plotBackgroundColor: null,
    plotBackgroundImage: null,
    plotBorderWidth: 0,
    plotShadow: false,
    height: "210px",
  },

  title: {
    text: null,
  },

  credits: {
    enabled: false,
  },

  pane: {
    startAngle: -90,
    endAngle: 89.9,
    background: null,
    center: ["50%", "90%"],
    size: "160%",
    innerSize: "30%",
  },

  // the value axis
  yAxis: {
    min: 0,
    max: 200,
    tickPixelInterval: 50,
    tickPosition: "inside",
    tickColor: "white",
    tickLength: 20,
    tickWidth: 0,
    height: 100,
    innerRadius: "75%",
    minorTickInterval: null,
    labels: {
      distance: 15,
      style: {
        fontSize: "14px",
      },
    },
    plotBands: [
      {
        from: 0,
        to: 120,
        color: "#55C95A", // green
        thickness: 60,
      },
      {
        from: 120,
        to: 160,
        color: "#FFBC00", // yellow
        thickness: 60,
      },
      {
        from: 160,
        to: 200,
        color: "#FE4545", // red
        thickness: 60,
      },
    ],
  },

  series: [
    {
      name: "Speed",
      data: [80],
      tooltip: {
        valueSuffix: " km/h",
      },
      dataLabels: {
        format: "{y} km/h",
        borderWidth: 0,
        color: "#FFF",
        style: {
          fontSize: "16px",
        },
        backgroundColor: "black",
        zIndex: 5,
      },
      dial: {
        radius: "80%",
        backgroundColor: "black",
        baseWidth: 16,
        baseLength: "0%",
        rearLength: "0%",
      },
      pivot: {
        backgroundColor: "gray",
        radius: 6,
      },
    },
  ],
};

const dummyMultiItemCharts = {
  chart: {
    zoomType: "xy",
    height: 250,
    width: 1000,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      groupPadding: 0.2,
      pointPadding: 0,
    },
  },
  xAxis: [
    {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: "{value}",
        style: {
          color: "black",
        },
      },
      title: {
        text: "RPM",
        style: {
          color: "black",
        },
      },
      lineColor: "rgba(0,0,0,.1)",
      lineWidth: 1,
      tickColor: "rgba(0,0,0,.1)",
      tickWidth: 1,
      tickLength: 5,
      // opposite: true,
    },
    {
      // Secondary yAxis
      gridLineWidth: 0,
      title: {
        text: "Liters",
        style: {
          color: "#F34584",
        },
      },
      labels: {
        format: "{value}",
        style: {
          color: "#F34584",
        },
      },
      opposite: false,
      lineColor: "#F34584",
      lineWidth: 1,
      tickColor: "#F34584",
      tickWidth: 1,
      tickLength: 5,
    },
    {
      // Tertiary yAxis
      gridLineWidth: 0,
      title: {
        text: "Knot",
        style: {
          color: "#FF9A3D",
        },
      },
      labels: {
        format: "{value}",
        style: {
          color: "#FF9A3D",
        },
      },
      opposite: true,
      lineColor: "#FF9A3D",
      lineWidth: 1,
      tickColor: "#FF9A3D",
      tickWidth: 1,
      tickLength: 5,
    },
  ],
  tooltip: {
    shared: true,
  },
  // legend: {
  //   // layout: "vertical",
  //   align: "center",
  //   // x: 180,
  //   // verticalAlign: "top",
  //   // y: 25,
  //   // floating: true,
  //   backgroundColor: "#FFFFFF",
  // },
  series: [
    {
      name: "Port RPM",
      type: "column",
      yAxis: 0,
      data: [
        7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
      ],
      tooltip: {
        valueSuffix: " RPM",
      },
      color: "#6F57E9",
    },
    {
      name: "Starboard RPM",
      type: "column",
      yAxis: 0,
      data: [6.0, 5.9, 8.5, 10.5, 9.2, 5.5, 15.2, 20.5, 20.3, 13.3, 8.9, 9.6],
      tooltip: {
        valueSuffix: " RPM",
      },
      color: "#02CCCC",
    },
    {
      name: "Liters",
      type: "line",
      yAxis: 1,
      data: [
        49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
        95.6, 54.4,
      ],
      marker: {
        enabled: true,
        symbol: "circle",
      },
      tooltip: {
        valueSuffix: " liter",
      },
      color: "#F34584",
    },
    {
      name: "Speed",
      type: "line",
      yAxis: 2,
      data: [
        1016, 1016, 1015.9, 1015.5, 1012.3, 1009.5, 1009.6, 1010.2, 1013.1,
        1016.9, 1018.2, 1016.7,
      ],
      marker: {
        enabled: true,
        symbol: "circle",
      },
      dashStyle: "dash",
      tooltip: {
        valueSuffix: " knot",
      },
      color: "#FF9A3D",
    },
  ],
};

const columns: any = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "Unit",
    dataIndex: "unit",
    key: "unit",
  },
  {
    title: "Description",
    dataIndex: "description2",
    key: "description2",
  },
  {
    title: "Value",
    dataIndex: "value2",
    key: "value2",
  },
  {
    title: "Unit",
    dataIndex: "unit2",
    key: "unit2",
  },
];

const columnsVesselTracking: any = [
  {
    title: "   ",
    dataIndex: "header",
    key: "header",
  },
  {
    title: "Position",
    dataIndex: "position",
    key: "position",
    onCell: (_: any, index: number) => ({
      colSpan: index === 3 || index === 2 ? 3 : 1,
    }),
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    onCell: (_: any, index: number) => ({
      colSpan: index === 3 || index === 2 ? 0 : 1,
    }),
  },
  {
    title: "Speed",
    dataIndex: "speed",
    key: "speed",
    onCell: (_: any, index: number) => ({
      colSpan: index === 3 || index === 2 ? 0 : 1,
    }),
  },
];

const columnsRPMSpeed: any = [
  {
    title: "Hour",
    dataIndex: "hour",
    key: "hour",
  },
  ...Array.from({ length: 24 }, (_, i) => ({
    title: `${(i + 17) % 24}`,
    dataIndex: `${(i + 17) % 24}`,
    key: `${(i + 17) % 24}`,
  })),
];

const columnsPortFuel: any = [
  {
    title: "Hour",
    dataIndex: "hour",
    key: "hour",
  },
  ...Array.from({ length: 24 }, (_, i) => ({
    title: `${(i + 17) % 24}`,
    dataIndex: `${(i + 17) % 24}`,
    key: `${(i + 17) % 24}`,
  })),
];

const columnsStarboardFuel: any = [
  {
    title: "Hour",
    dataIndex: "hour",
    key: "hour",
  },
  ...Array.from({ length: 24 }, (_, i) => ({
    title: `${(i + 17) % 24}`,
    dataIndex: `${(i + 17) % 24}`,
    key: `${(i + 17) % 24}`,
  })),
];

const columnsMEFuel: any = [
  {
    title: "Hour",
    dataIndex: "hour",
    key: "hour",
  },
  ...Array.from({ length: 24 }, (_, i) => {
    const columnIndex = (i + 17) % 24;
    return {
      title: `${columnIndex}`,
      dataIndex: `${columnIndex}`,
      key: `${columnIndex}`,
      onCell: (_: any, index: number) => {
        if (index === 2 || index === 3) {
          if (columnIndex === 17) {
            return { colSpan: 25 };
          } else {
            return { colSpan: 0 };
          }
        }
      },
    };
  }),
];

const columnsAEFuel: any = [
  {
    title: "Hour",
    dataIndex: "hour",
    key: "hour",
  },
  ...Array.from({ length: 24 }, (_, i) => {
    const columnIndex = (i + 17) % 24;
    return {
      title: `${columnIndex}`,
      dataIndex: `${columnIndex}`,
      key: `${columnIndex}`,
      onCell: (_: any, index: number) => {
        if (index === 3) {
          if (columnIndex === 17) {
            return { colSpan: 25 };
          } else {
            return { colSpan: 0 };
          }
        }
      },
    };
  }),
];

export {
  optionsGauge,
  dummyMultiItemCharts,
  columns,
  columnsAEFuel,
  columnsMEFuel,
  columnsPortFuel,
  columnsRPMSpeed,
  columnsStarboardFuel,
  columnsVesselTracking,
};
