import React from "react";
import { Typography } from "antd";
import styled from "styled-components";
import ImageWrapper from "./ImageWrapper";
import Divider from "./Divider";
import { TitleReport } from "../../screens/report/dailyReport";
import Logo from "../../assets/images/logo.png";

const { Paragraph } = Typography;

const Header = () => {
  return (
    <>
      <TitleReport>
        <Paragraph style={{ fontWeight: "600", fontSize: 18 }}>
          Daily Report
        </Paragraph>
      </TitleReport>
      <ReportInfo>
        <div>
          <Paragraph style={{ fontWeight: "500" }}>
            Vessel: TB Megawati
          </Paragraph>
          <Paragraph style={{ fontSize: 10, marginTop: -10 }}>
            Analyzed data 09 Dec 2023 17:00 to 10 Dec 2023 16:59 GMT +007
          </Paragraph>
        </div>
        <div>
          <ImageWrapper
            src={Logo}
            alt="Logo Ramus"
            title="Logo Ramus"
            width={120}
            height={120}
            style={{ objectFit: "contain" }}
          />
        </div>
      </ReportInfo>
      <Divider />
    </>
  );
};

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  margin-bottom: 100px;
`;

const ReportInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default Header;
