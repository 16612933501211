import AppJson from "../../package.json";

type IConfig = {
  pageTitlePosition: "top-nav" | "content";
  /** if top-nav, breadcrumbs will be shown in content. If content, breadcrumbs will be shown in top-nav. */

  defaultPath: string;

  topNav: {
    showWelcomeName: boolean;
  };

  sidebar: {
    logoType: "image-and-text" | "text-only";
  };

  user: {
    modeEditName: "fullname" | "first-middle-last";

    showEditEmail: boolean;
    changeEmailWithOTP: boolean;

    showEditPhone: boolean;
    changePhoneWithOTP: boolean;

    phoneRequired: boolean;
    emailRequired: boolean;

    hasAddress: boolean;
  };

  myProfile: {
    showPermission: boolean;
  };
};
const CONFIG: IConfig = {
  //config mode bahasa

  pageTitlePosition: "content",

  defaultPath: "/home",

  topNav: {
    showWelcomeName: false,
  },

  sidebar: {
    logoType: "image-and-text",
  },

  user: {
    // modeEditName: 'fullname',
    modeEditName: "first-middle-last",

    showEditEmail: true,
    showEditPhone: true,

    phoneRequired: false,
    changePhoneWithOTP: false,

    emailRequired: true,
    changeEmailWithOTP: true,

    hasAddress: true,
  },

  myProfile: {
    showPermission: false,
  },
};

export const SUPER_ADMIN_ROLE_ID = "0747b5d6-1a5c-4955-a9c9-91122884da99";

export const DEFAULT_IMG = "/images/default_image.png";

export const APP_VERSION = AppJson.version;

export const APP_LOGO_SRC = "/logo-white.svg";
export const APP_LOGO_SRC_COLLAPSED = "/logo-white.svg";

export const APP_LANGUAGE_TYPE =
  process.env.REACT_APP_LANGUAGE_TYPE || "frontend" || "backend";

export default CONFIG;

export enum EDateFormat {
  COMPLETE = "yyyy-MM-dd HH:mm:ss",
  DEFAULT = "yyyy-MM-dd HH:mm",
  DEFAULT_WITHOUT_TIME = "yyyy-MM-dd HH:mm",
  SHORT = "yy-MM-dd HH:mm",
  DATE_ONLY = "yy-MM-dd",
}
