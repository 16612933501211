import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import useMasterData from "../hooks/useMasterData";
import useCompanies from "../zustand/useGroup";

type FilterReportType = {};

const { RangePicker } = DatePicker;

const FilterReport: React.FC<FilterReportType> = () => {
  const [form] = Form.useForm();
  const { company } = useCompanies();
  const { getListCompanies, getListAssets, companies, assets } =
    useMasterData();

  const [companyId, setCompanyId] = useState<string>("");

  const calculateRange = (type: string) => {
    switch (type) {
      case "Last 30 Days":
        return [moment().subtract(30, "days"), moment()];
      case "Last Month":
        return [
          moment().subtract(1, "months").startOf("month"),
          moment().subtract(1, "months").endOf("month"),
        ];
      case "Last 7 Days":
        return [moment().subtract(7, "days"), moment()];
      case "Last Week":
        return [
          moment().subtract(1, "weeks").startOf("week"),
          moment().subtract(1, "weeks").endOf("week"),
        ];
      case "Today":
        return [moment().startOf("day"), moment().endOf("day")];
      case "Last Day":
        return [
          moment().subtract(1, "days").startOf("day"),
          moment().subtract(1, "days").endOf("day"),
        ];
      default:
        return undefined;
    }
  };

  const filterAssetOptions = useMemo(() => {
    const data = assets.map((item) => ({
      value: item.assetId,
      label: item.name,
    }));
    return [...data];
  }, [assets]);

  const filterCompanyOptions = useMemo(() => {
    const data = companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
    return [...data];
  }, [companies]);

  const handleRangeTypeChange = (rangeType: string) => {
    const range = calculateRange(rangeType);
    form.setFieldsValue({
      rangeType,
      range,
    });
  };

  useEffect(() => {
    getListCompanies();
    form.setFieldsValue({
      rangeType: "Today",
      interval: "Daily",
      range: calculateRange("Today"),
    });
  }, []);

  useEffect(() => {
    if (companyId) {
      getListAssets({ filterCompanyIds: companyId });
    }
  }, [companyId]);

  useEffect(() => {
    if (assets.length > 0) {
      const firstAsset = assets[0];
      form.setFieldsValue({
        asset: firstAsset.assetId,
      });
    }
  }, [assets]);

  useEffect(() => {
    if (companies) {
      form.setFieldsValue({
        company: company.companyId,
      });
      setCompanyId(company.companyId);
    }
  }, [companies]);

  const _renderDeviceInfo = (
    device: string,
    valueDevice: any,
    lastDataDevice: any
  ) => {
    return (
      <Col span={4} style={{ display: "flex", alignItems: "center", gap: 5 }}>
        {device !== "Gateway" && (
          <Divider type="vertical" style={{ height: "100%" }} />
        )}
        <div>
          <p>
            {device}: {valueDevice}
          </p>
          <p style={{ color: "grey", marginTop: -5 }}>
            Last Data: {lastDataDevice}
          </p>
        </div>
      </Col>
    );
  };

  return (
    <Card style={{ marginTop: 10, boxShadow: "none" }}>
      <Row>
        {_renderDeviceInfo("Gateway", "0.00%", "-")}
        {_renderDeviceInfo("GPS", "0.00%", "-")}
        {_renderDeviceInfo("RPM", "0.00%", "-")}
        {_renderDeviceInfo("Flowmeter", "0.00%", "-")}
        {_renderDeviceInfo("AE", "0.00%", "-")}
        {_renderDeviceInfo("Power", "0.00%", "-")}
      </Row>
      <Divider style={{ marginTop: -2 }} />
      <Form form={form} name="profileForm" layout="vertical" autoComplete="off">
        <Row gutter={15}>
          <Col span={5}>
            <Form.Item label="Company" name="company">
              <Select
                showSearch
                placeholder="Choose company"
                style={{ width: "100%" }}
                onChange={(e) => {
                  setCompanyId(e);
                  form.setFieldValue("asset", undefined);
                }}
                options={filterCompanyOptions}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Asset" name="asset">
              <Select
                showSearch
                placeholder="Choose asset"
                style={{ width: "100%" }}
                onChange={(e) => {}}
                options={filterAssetOptions}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Range Type" name="rangeType">
              <Select
                showSearch
                placeholder="Choose range type"
                style={{ width: "100%" }}
                onChange={handleRangeTypeChange}
                options={[
                  {
                    label: "Last 30 Days",
                    value: "Last 30 Days",
                  },
                  {
                    label: "Last Month",
                    value: "Last Month",
                  },
                  {
                    label: "Last 7 Days",
                    value: "Last 7 Days",
                  },
                  {
                    label: "Last Week",
                    value: "Last Week",
                  },
                  {
                    label: "Today",
                    value: "Today",
                  },
                  {
                    label: "Last Day",
                    value: "Last Day",
                  },
                  {
                    label: "Custom",
                    value: "Custom",
                  },
                ]}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Range" name="range">
              <RangePicker style={{ width: "100%" }} showTime />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Interval" name="interval">
              <Select
                showSearch
                placeholder="Choose interval"
                style={{ width: "100%" }}
                onChange={(e) => {}}
                options={[
                  {
                    label: "Minutes",
                    value: "Minutes",
                  },
                  {
                    label: "Hourly",
                    value: "Hourly",
                  },
                  {
                    label: "Daily",
                    value: "Daily",
                  },
                  {
                    label: "Weekly",
                    value: "Weekly",
                  },
                  {
                    label: "Monthly",
                    value: "Monthly",
                  },
                ]}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button type="primary" style={{ marginTop: 30 }}>
              Apply
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default FilterReport;
